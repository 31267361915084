<template>
  <div
    :id="`video-bg-image-${dynamicNumber}`"
    class="ProjectVideoBgImage"
    :class="`${content.vertical_margin}`"
    :style="`background : ${customColor};`"
  >
    <div class="ProjectVideoBgImage__container">
      <img
        v-if="!$store.state.isMobile && content.bg_image"
        :data-src="getImageKitURL(content.bg_image, 1, 'image',false,position)"
        :src="getImageKitURL(content.bg_image, 1, 'image',true,position)"
        :alt="content.bg_image.filename"
        class="ProjectVideoBgImage__image"
      >
      <div
        v-if="content.vimeo_id"
        class="ProjectVideoBgImage__video"
        :class="{'hide': content.hide_video_for_mb}"
      >
        <iframe
          :src="`https://player.vimeo.com/video/${content.vimeo_id}?background=1`"
          frameborder="0"
          width="900"
          height="1600"
          allow="autoplay"
          title="video"
        />
      </div>
    </div>



    <img
      v-if="$store.state.isMobile && content.mb_image"
      :data-src="getImageKitURL(content.mb_image, 1, 'image',false,position)"
      :src="getImageKitURL(content.mb_image, 1, 'image',true,position)"
      :alt="content.mb_image.filename"
      class="ProjectVideoBgImage__image"
    >
  </div>
</template>

<script>
/* Mixins */
import imageKit from "@/mixins/image-kit";

export default {
  mixins: [imageKit],
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    position:{
      type: Number,
      default: 1000
    },
  },

  computed: {
    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },

    customColor() {
      if (this.content.bg_color) {
        return this.content.bg_color;
      } else {
        return "transparent";
      }
    },
  },
};
</script>

<style lang="scss">
.ProjectVideoBgImage {
position: relative;
display: flex;
flex-direction: column;
width: 100%;

  &.bottom-text {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmall;
    }
  }

  &.bottom-standard {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottom;
    }
  }

  &.bottom-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomBig;
    }
  }

  &.bottom-super-big {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSuper;
    }
  }

  &.bottom-small {
    @include paddingMobileBottom;

    @include tablet {
      @include paddingBottomSmallProject;
    }
  }

&__container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}

&__image {
  width: 100%;
  height: auto;
}

&__video {
  display: flex;
  justify-content: center;
   margin-bottom: 5vw;

  iframe {
  width: 100vw;
  height: 177.78vw;
  }

  &.hide {
    display: none;

    @include tablet {
      display: flex;
    }
  }

  @include tablet {
    position: absolute;
    top: 50%;
    left: 50.15%;
    z-index: 3;
    display: flex;
    margin-bottom: 0;
    transform: translate(-50%, -50%);

    iframe {
    width: 21vw;
    height: 37.34vw;
    }
  }

}
}
</style>
